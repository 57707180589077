<template>
  <div>
    <nav v-if="!$store.getters.isMobile" style="padding: 30px;" :style="themeStyles">
      <div style="display: flex; justify-content: space-between;">
        <div @click="redirectToStreamlining" style="cursor: pointer; ">GrowMyStore</div>
        <i v-if="isAboutPage" @click="toggleTheme" :class="{ 'fa-regular fa-moon': $store.getters.isDarkTheme, 'fa-regular fa-sun': !$store.getters.isDarkTheme }"></i>
      </div>
    </nav>
    <nav v-if="$store.getters.isMobile" style="padding: 30px;" :style="themeStyles">
      <div style="display: flex; justify-content: space-between;">
        <div @click="redirectToStreamlining" style="cursor: pointer; font-size: 16px;">GrowMyStore</div>
        <i v-if="isAboutPage" @click="toggleTheme" :class="{ 'fa-regular fa-moon': $store.getters.isDarkTheme, 'fa-regular fa-sun': !$store.getters.isDarkTheme }"></i>
      </div>
    </nav>
  </div>
  <router-view/>
</template>

<script>
export default {
  created() {
    this.$store.commit('setDeviceInfo');
    this.$store.commit('setMobile');
  },
  computed: {
    isAboutPage() {
      return this.$route.name === 'dopecars'; // Replace 'About' with the actual name of your about page route
    },
    themeStyles() {
      const isAboutPage = this.$route.name === 'dopecars'; // Replace 'About' with the actual name of your about page route
      const isDarkTheme = this.$store.getters.isDarkTheme;

      // Apply dark theme styles only on the About page
      if (isAboutPage && isDarkTheme) {
        return { backgroundColor: '#181818', color: '#dddddd' }; // Dark mode styles
      } else {
        return { backgroundColor: '#ffffff', color: '#2c3e50' }; // Light mode styles
      }
    }
  },
  methods: {
    redirectToStreamlining() {
      // Using router-link
      // You'd replace 'StreamliningOperations' with the name of your route
      this.$router.push({ name: 'home' });

      // Or, if you're importing the route path directly
      // this.$router.push('/streamlining-operations-at-dope-cars');
    },
    toggleTheme() {
      this.$store.commit('toggleTheme');
    },
  }
};
</script>

<style>
#app {
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  text-align: left;
  background-color: #ffffff;
  box-shadow: 0px 2px 50px rgba(0, 0, 0, 0.2); /* Adding a shadow effect */
  color: black; /* Changing text color for visibility */
  padding: 30px;
  font-size: 20px;
  font-weight:800;
  margin: -8px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
