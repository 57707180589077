<template>
  <div v-if="!$store.getters.isMobile" class="main-content">
    <!-- Main content desktop-->
    <div >
      <!-- Left column with 60% width -->
      <div class="left-column" style="width: 60%; float: left; text-align: left;">
        <h1 style="margin-bottom: 15px; font-size: 52px;">Simplify Operations, Amplify Success.</h1>
        <p style="font-size: 19px; margin-top: 0%; line-height: 1.5;">Let your operations run like a fine-tuned machine, allowing you to concentrate on what truly matters.</p>
        <button @click="redirectToExternalLink(ContactUs)" class="hover-grow">Contact us</button>
        <p style="font-size: 19px; margin: 20px 0%">★★★★★ 5 stars from 100+ users</p>
      </div>

      <!-- Right column with 40% width -->
      <div class="right-column" style="width: 40%; float: right;">
        <img :src="require('@/assets/hero.png')" alt="Hero Image" style="width: 100%; padding-top: 30px;" />
      </div>

      <!-- Clear float -->
      <div style="clear: both;"></div>
    </div>

    <!-- Our Services desktop -->
    <div style="text-align: left; padding: 80px 20px 40px 20px;">
      <h1 style="font-size: 36px; margin-bottom: 15px;">Our Services</h1>
      <div class="card-container">
        <div class="card">
          <i class="fa-brands fa-shopify fa-2xl" style="padding: 40px 10px; scale: 1.6; color: teal;"></i>
          <h2>Shopify</h2>
          <p style="font-size: 19px; margin-top: 0%; line-height: 1.5;">Effortlessly manage daily operations, from inventory to customer engagement, while focusing on growth and customer satisfaction.</p>
          <a @click="scrollToSection" style="cursor: pointer; text-decoration: underline;" class="hover-grow">Learn more</a>
        </div>
        <div class="card">
          <i class="fa-solid fa-headphones-simple fa-2xl" style="padding: 40px 10px; scale: 1.6; color: teal;"></i>
          <h2>Customer Support</h2>
          <p style="font-size: 19px; margin-top: 0%; line-height: 1.5;">Elevate your customer support with prompt and friendly assistance, ensuring growth and customer satisfaction.</p>
          <a @click="scrollToSection" style="cursor: pointer; text-decoration: underline;" class="hover-grow">Learn more</a>
        </div>
        <div class="card">
          <i class="fa-solid fa-truck-fast fa-2xl" style="padding: 40px 10px; scale: 1.6; color: teal;"></i>
          <h2>Logistics</h2>
          <p style="font-size: 19px; margin-top: 0%; line-height: 1.5;">Ensure on-time delivery, Efficient order management,  RTO, NDR and order status to customer verification before shipping.</p>
          <a @click="scrollToSection" style="cursor: pointer; text-decoration: underline;" class="hover-grow">Learn more</a>
        </div>
      </div>
    </div>

    <!--great features desktop -->
    <div style="padding-top: 40px;">
      <!-- Left column with 60% width -->
      <div class="left-column" style="width: 60%; float: left; text-align: left;">
        <h1 style="font-size: 36px; margin-bottom: 30px;">A bunch of great features to build a robust business</h1>
        <p style="font-size: 19px; margin-top: 0%; margin-bottom: 10px; line-height: 1.5;">
          Empower Your Business with These Game-Changing Features!
        </p>
        <ul style="margin-top: 10px; line-height: 2; font-size: 19px;">
          <li>Dedicated In-House ticketing app</li>
          <li>Weekly report to Analyse your business</li>
          <li>Shopify SEO optimization and a bunch of other tools</li>
        </ul>
      </div>

      <!-- Right column with 40% width -->
      <div class="right-column" style="width: 40%; float: right;">
        <img :src="require('@/assets/technologies.png')" alt="Hero Image" style="width: 100%; padding-top: 10px;" />
      </div>

      <!-- Clear float -->
      <div style="clear: both;"></div>
    </div>

    <!-- Our Pricing desktop -->
    <div style="text-align: left; padding: 80px 20px 40px 20px;">
      <h1 style="font-size: 36px; margin-bottom: 40px;">Pricing</h1>
      <div class="card-container">
        <div class="card">
          <i class="fa-solid fa-location-arrow fa-2xl" style="padding: 40px 10px; scale: 1.6; color: teal;"></i>
          <div>Starters</div>
          <h1 style="padding-top: 10px; font-size: 40px;">₹5000/m</h1>
          <ul style="margin-top: 10px; line-height: 2; font-size: 19px; padding-left: 20px;">
            <li>Shopify Management</li>
            <li>Logistics Management</li>
            <li>Limited Customer support</li>
            <li>Inventory Management</li>
            <li>3 P2 tickets</li>
            <li>5 P3 tickets</li>
            <li>10 P4 tickets</li>
            <li>15 P5 tickets</li>
          </ul>
          <div style="text-align: center;">
            <button class="hover-grow" @click="redirectToExternalLink(Starter)" style="font-size: medium;">Learn more</button>
          </div>
          
        </div>
        <div class="card" style="background-color: teal; color: white; scale: 1.1;">
          <i class="fa-solid fa-bolt fa-2xl" style="padding: 50px 10px; scale: 1.6; color: white;"></i>
          <div>Pro</div>
          <h1 style="padding-top: 10px; font-size: 40px;">₹7000/m</h1>
          <ul style="margin-top: 10px; line-height: 2; font-size: 19px; padding-left: 20px;">
            <li>All the starter features</li>
            <li>7 P2 tickets</li>
            <li>10 P3 tickets</li>
            <li>15 P4 tickets</li>
            <li>20 P5 tickets</li>
            <li>Weekly report</li>
            <li>Shopify Store Creation</li>
            <li>Shopify App Integration</li>
          </ul>
          <div style="text-align: center;">
            <button class="hover-grow" @click="redirectToExternalLink(Pro)" style="background-color: white; color: teal; font-size: medium;">Learn more</button>
          </div>
        </div>
        <div class="card">
          <i class="fa-solid fa-briefcase fa-2xl" style="padding: 40px 10px; scale: 1.6; color: teal;"></i>
          <div>Business</div>
          <h1 style="padding-top: 10px; font-size: 40px;">₹10000/m</h1>
          <ul style="margin-top: 10px; line-height: 2; font-size: 19px; padding-left: 20px;">
            <li>All the Pro features</li>
            <li>2 P1 tickets</li>
            <li>7 P2 tickets</li>
            <li>10 P3 tickets</li>
            <li>15 P4 tickets</li>
            <li>20 P5 tickets</li>
            <li>Full Customer support</li>
            <li>Vendor Management</li>
          </ul>
          <div style="text-align: center;">
            <button class="hover-grow" @click="redirectToExternalLink(Business)" style="font-size: medium;">Learn more</button>
          </div>
        </div>  
      </div>
    </div>

    <!--great features desktop -->
    <div style="padding-top: 80px;">
      <!-- Left column with 60% width -->
      <div class="left-column" style="width: 60%; float: left; text-align: left;">
        <h1 style="font-size: 36px; margin-bottom: 30px;">92% of customers that used our product increased their profit.</h1>
        <button @click="redirectToExternalLink(ContactUs)" class="hover-grow">Contact us</button>
        <p style="font-size: 19px; margin: 20px 0%">★★★★★ 5 stars from 100+ users</p>
      </div>

      <!-- Right column with 40% width -->
      <div class="right-column" style="width: 40%; float: right;">
        <img :src="require('@/assets/technologies.png')" alt="Hero Image" style="width: 100%; padding-top: 10px;" />
      </div>

      <!-- Clear float -->
      <div style="clear: both;"></div>
    </div>


    <!--Profit Increase desktop -->
    <div style="text-align: left; padding: 60px 20px 40px 20px;">
      <h1 style="font-size: 36px; margin-bottom: 15px;" ref="scrollTarget" id="scrollTarget">Case Studies</h1>
      <div class="card-container">
        <div class="card hover-grow" style="padding: 0%; width: 48%; cursor: pointer;" @click="redirectToStreamlining">
          <img src="@/assets/dopecars.png" alt="Shopify">
          <div class="card-details">
            <span class="published-time">Published: June 2023</span>
            <h2 style="margin-top: 10px;">Streamlining Operations at Dope Cars.</h2>
          </div>
        </div>
        <div class="card hover-grow" style="padding: 0%; width: 48%; cursor: pointer;">
          <img src="@/assets/dopecars.png" alt="Customer Support">
          <div class="card-details">
            <span class="published-time">Published: May 2023</span>
            <h2>Decor Mantra</h2>
          </div>
        </div>
      </div>
    </div>

    <!-- review desktop -->
    <div class="review-section">
      <h1 style="font-size: 36px; margin-bottom: 15px;">What our customers think</h1>
      <div class="review">
        <p><strong>DopeCars</strong></p>
        <p class="rating ratingcolor">★★★★★ <span style="color: black; font-size: 18px;">5/5</span></p> 
        <p>"Absolutely outstanding service! The customer support team was incredibly responsive and helpful, and their logistics management is second to none. I couldn't be happier with my experience!"</p>
      </div>
      <div class="review">
        <p><strong>Decor Mantra</strong></p>
        <p class="rating ratingcolor">★★★★★ <span style="color: black; font-size: 18px;">5/5</span></p> 
        <p>"I am thoroughly impressed with this company's efficient order management and inventory management. Their proactive approach to reducing RTO and increasing revenue is a game-changer. Five stars all the way!"</p>
      </div>
    </div>
  </div>


  <div v-if="$store.getters.isMobile" class="main-content" style="margin: 20px auto;">
    <!-- Main content mobile-->
    <div >
      <!-- Left column with 60% width -->
      <div class="left-column" style=" float: left; text-align: left;">
        <h1 style="margin-bottom: 15px; font-size: 46px;">Simplify Operations, Amplify Success.</h1>
        <p style="font-size: 16px; margin-top: 0%; line-height: 1.5;">Let your operations run like a fine-tuned machine, allowing you to concentrate on what truly matters.</p>
        <button @click="redirectToExternalLink(ContactUs)" class="hover-grow buttonMobile" style="">Contact us</button>
        <p style="font-size: 16px; margin: 20px 0%">★★★★★ 5 stars from 100+ users</p>
      </div>

      <!-- Right column with 40% width -->
      <div class="right-column" style="float: right;">
        <img :src="require('@/assets/hero.png')" alt="Hero Image" style="width: 100%; padding-top: 30px;" />
      </div>

      <!-- Clear float -->
      <div style="clear: both;"></div>
    </div>

    <!-- Our Services mobile -->
    <div style="text-align: left; padding: 50px 20px 40px 20px;">
      <h1 style="font-size: 32px; margin-bottom: 35px; ">Our Services</h1>
      <div>
        <div class="cardMobile" style="padding-bottom: 60px;">
          <i class="fa-brands fa-shopify fa-2xl" style="padding: 40px 10px; scale: 1.6; color: teal;"></i>
          <h2>Shopify</h2>
          <p style="font-size: 16px; margin-top: 0%; line-height: 1.5;">Effortlessly manage daily operations, from inventory to customer engagement, while focusing on growth and customer satisfaction.</p>
          <a @click="scrollToSection" style="cursor: pointer; text-decoration: underline;" class="hover-grow">Learn more</a>
        </div>
        <div class="cardMobile" style="padding-bottom: 60px;">
          <i class="fa-solid fa-headphones-simple fa-2xl" style="padding: 40px 10px; scale: 1.6; color: teal;"></i>
          <h2>Customer Support</h2>
          <p style="font-size: 16px; margin-top: 0%; line-height: 1.5;">Elevate your customer support with prompt and friendly assistance, ensuring growth and customer satisfaction.</p>
          <a @click="scrollToSection" style="cursor: pointer; text-decoration: underline;" class="hover-grow">Learn more</a>
        </div>
        <div class="cardMobile" style="padding-bottom: 60px;">
          <i class="fa-solid fa-truck-fast fa-2xl" style="padding: 40px 10px; scale: 1.6; color: teal;"></i>
          <h2>Logistics</h2>
          <p style="font-size: 16px; margin-top: 0%; line-height: 1.5;">Ensure on-time delivery, Efficient order management,  RTO, NDR and order status to customer verification before shipping.</p>
          <a @click="scrollToSection" style="cursor: pointer; text-decoration: underline;" class="hover-grow">Learn more</a>
        </div>
      </div>
    </div>

    <!--great features mobile -->
    <div>
      <!-- Left column with 60% width -->
      <div class="left-column" style="float: left; text-align: left;">
        <h1 style="font-size: 32px; margin-bottom: 30px;">A bunch of great features to build a robust business</h1>
        <p style="font-size: 16px; margin-top: 0%; margin-bottom: 10px; line-height: 1.5;">
          Empower Your Business with These Game-Changing Features!
        </p>
        <ul style="margin-top: 10px; line-height: 2; font-size: 16px;">
          <li>Dedicated In-House ticketing app</li>
          <li>Weekly report to Analyse your business</li>
          <li>Shopify SEO optimization and a bunch of other tools</li>
        </ul>
      </div>

      <!-- Right column with 40% width -->
      <div class="right-column" style="float: right;">
        <img :src="require('@/assets/technologies.png')" alt="Hero Image" style="width: 100%; padding-top: 10px;" />
      </div>

      <!-- Clear float -->
      <div style="clear: both;"></div>
    </div>

    <!-- Our Pricing mobile -->
    <div style="text-align: left; padding: 45px 20px 40px 20px;">
      <h1 style="font-size: 32px; margin-bottom: 40px;">Pricing</h1>
      <div>
        <div class="cardMobile">
          <i class="fa-solid fa-location-arrow fa-2xl" style="padding: 40px 10px; scale: 1.6; color: teal;"></i>
          <div>Starters</div>
          <h1 style="padding-top: 10px; font-size: 40px;">₹5000/m</h1>
          <ul style="margin-top: 10px; line-height: 2; font-size: 16px; padding-left: 20px;">
            <li>Shopify Management</li>
            <li>Logistics Management</li>
            <li>Limited Customer support</li>
            <li>Inventory Management</li>
            <li>3 P2 tickets</li>
            <li>5 P3 tickets</li>
            <li>10 P4 tickets</li>
            <li>15 P5 tickets</li>
          </ul>
          <div style="text-align: center;">
            <button class="hover-grow" @click="redirectToExternalLink(Starter)" style="font-size: medium;">Learn more</button>
          </div>
          
        </div>
        <div class="cardMobile" style="background-color: teal; color: white; scale: 1.1;">
          <i class="fa-solid fa-bolt fa-2xl" style="padding: 50px 10px; scale: 1.6; color: white;"></i>
          <div>Pro</div>
          <h1 style="padding-top: 10px; font-size: 40px;">₹7000/m</h1>
          <ul style="margin-top: 10px; line-height: 2; font-size: 16px; padding-left: 20px;">
            <li>All the starter features</li>
            <li>7 P2 tickets</li>
            <li>10 P3 tickets</li>
            <li>15 P4 tickets</li>
            <li>20 P5 tickets</li>
            <li>Weekly report</li>
            <li>Shopify Store Creation</li>
            <li>Shopify App Integration</li>
          </ul>
          <div style="text-align: center;">
            <button class="hover-grow" @click="redirectToExternalLink(Pro)" style="background-color: white; color: teal; font-size: medium;">Learn more</button>
          </div>
        </div>
        <div class="cardMobile">
          <i class="fa-solid fa-briefcase fa-2xl" style="padding: 40px 10px; scale: 1.6; color: teal;"></i>
          <div>Business</div>
          <h1 style="padding-top: 10px; font-size: 40px;">₹10000/m</h1>
          <ul style="margin-top: 10px; line-height: 2; font-size: 16px; padding-left: 20px;">
            <li>All the Pro features</li>
            <li>2 P1 tickets</li>
            <li>7 P2 tickets</li>
            <li>10 P3 tickets</li>
            <li>15 P4 tickets</li>
            <li>20 P5 tickets</li>
            <li>Full Customer support</li>
            <li>Vendor Management</li>
          </ul>
          <div style="text-align: center;">
            <button class="hover-grow" @click="redirectToExternalLink(Business)" style="font-size: medium;">Learn more</button>
          </div>
        </div>  
      </div>
    </div>

    <!--great features mobile -->
    <div style="padding-top: 10px;">
      <!-- Left column with 60% width -->
      <div class="left-column" style="float: left; text-align: left;">
        <h1 style="font-size: 32px; margin-bottom: 30px;">92% of customers that used our product increased their profit.</h1>
        <button @click="redirectToExternalLink(ContactUs)" class="hover-grow">Contact us</button>
        <p style="font-size: 16px; margin: 20px 0%">★★★★★ 5 stars from 100+ users</p>
      </div>

      <!-- Right column with 40% width -->
      <div class="right-column" style="float: right;">
        <img :src="require('@/assets/technologies.png')" alt="Hero Image" style="width: 100%; padding-top: 10px;" />
      </div>

      <!-- Clear float -->
      <div style="clear: both;"></div>
    </div>


    <!--Profit Increase mobile -->
    <div style="text-align: left; padding: 50px 20px 40px 20px;">
      <h1 style="font-size: 32px; margin-bottom: 15px;" ref="scrollTarget" id="scrollTarget">Case Studies</h1>
      <div>
        <div class="cardMobile hover-grow" style="padding: 40px 30px; cursor: pointer;" @click="redirectToStreamlining">
          <div >
            <span class="published-time">Published: June 2023</span>
            <h2 style="margin-top: 10px;">Streamlining Operations at Dope Cars.</h2>
          </div>
        </div>
        <div class="cardMobile hover-grow" style="padding: 40px 30px; cursor: pointer;">
          <div >
            <span class="published-time">Published: May 2023</span>
            <h2>Decor Mantra</h2>
          </div>
        </div>
      </div>
    </div>

    <!-- review mobile -->
    <div class="review-section" style="padding: 20px;">
      <h1 style="font-size: 32px; margin-bottom: 15px;">What our customers think</h1>
      <div class="review">
        <p><strong>DopeCars</strong></p>
        <p class="rating ratingcolor">★★★★★ <span style="color: black; font-size: 18px;">5/5</span></p> 
        <p>"Absolutely outstanding service! The customer support team was incredibly responsive and helpful, and their logistics management is second to none. I couldn't be happier with my experience!"</p>
      </div>
      <div class="review">
        <p><strong>Decor Mantra</strong></p>
        <p class="rating ratingcolor">★★★★★ <span style="color: black; font-size: 18px;">5/5</span></p> 
        <p>"I am thoroughly impressed with this company's efficient order management and inventory management. Their proactive approach to reducing RTO and increasing revenue is a game-changer. Five stars all the way!"</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      ContactUs: "https://api.whatsapp.com/send?phone=+919805862313&text=Hi,%20GrowMyStore",
      Starter: "https://api.whatsapp.com/send?phone=+919805862313&text=Hi%20GrowMyStore,%20I%20want%20to%20know%20more%20about%20the%20Starter%20plan.",
      Pro: "https://api.whatsapp.com/send?phone=+919805862313&text=Hi%20GrowMyStore,%20I%20want%20to%20know%20more%20about%20the%20Pro%20plan.",
      Business: "https://api.whatsapp.com/send?phone=+919805862313&text=Hi%20GrowMyStore,%20I%20want%20to%20know%20more%20about%20the%20Business%20plan.",
    };
  },
  methods: {
    redirectToStreamlining() {
      window.scrollTo({ top: 0, behavior: 'instant' });
      this.$router.push({ name: 'dopecars' });
    },
    scrollToSection() {
      const targetElement = this.$refs.scrollTarget;
      targetElement.scrollIntoView({ behavior: 'smooth' });
    },
    redirectToExternalLink(link) {
      window.open(link, "_blank");
    },
  },
};
</script>

<style>
/* Styling for main content */
.main-content {
  margin: 80px auto;
  max-width: 1024px;
}

a{
  color: teal;
}

button{
  background-color: teal;
  border-radius: 12px;
  border: 0px;
  padding: 20px 30px;
  color: rgb(255, 255, 255);
  font-weight: bold;
  font-size: 20px; 
  margin: 20px 0%;
  cursor: pointer;
}

.buttonMobile{
  padding: 15px 23px;
  font-size: 18px; 
  margin: 18px 0%;
  cursor: pointer;
}


/* Styling for columns */
.left-column,
.right-column {
  padding: 20px;
  box-sizing: border-box;
}

.card-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 30px 0px;
}

.card {
  width: 20%;
  position: relative;
  border-radius: 20px;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.1);
  background-color: white;
  padding: 30px 50px;
  margin-bottom: 20px;
}

.cardMobile {
  border-radius: 20px;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.1);
  background-color: white;
  padding: 30px 50px;
  margin-bottom: 20px;
}
.card img {
  width: 100%;
  height: auto;
  display: block;
  border-radius: 8px 8px 0 0;
}

.card-details {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 87.2%;
  backdrop-filter: blur(3px); /* Gaussian blur effect */
  background-color: rgba(0, 0, 0, 0.01);
  padding: 30px;
}

.card-details .published-time {
  font-size: 16px;
  color: #cac8c8;
}

.card-details h2 {
  font-size: 26px;
  margin: 5px 0 0;
  color: white;
}

.review-section {
  text-align: left;
  padding: 40px;
}

.review {
  background-color: #ffffff;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.1);
  padding: 20px 60px;
  margin-bottom: 20px;
  border-radius: 20px;
  font-size: 19px;
  line-height: 1.5;
  margin-top: 40px;
}

.rating {
  font-size: 24px;
  margin-top: 10px;
}

.ratingcolor{
  color: #ffcc00;
}

.hover-grow {
  transition: transform 0.1s ease-in-out; /* Smooth transition for the transform property */
}

.hover-grow:hover {
  transform: scale(1.1); /* Increase the size by 10% */
}
</style>