import { createStore } from 'vuex'
import platform from 'platform';

export default createStore({
  state: {
    deviceInfo: '',
    mobile: false,
    darkTheme: false
  },
  getters: {
    getDeviceInfo: state => state.deviceInfo,
    isMobile: state => state.mobile,
    isDarkTheme: state => state.darkTheme
  },
  mutations: {
    setDeviceInfo(state) {
      state.deviceInfo = platform.parse(navigator.userAgent);
    },
    setMobile(state) {
      const deviceType = state.deviceInfo && state.deviceInfo.os ? state.deviceInfo.os.family.toLowerCase() : '';
      const isMobile = ['ios', 'android'].some(type => deviceType.includes(type));
      state.mobile = isMobile;
    },
    toggleTheme(state) {
      state.darkTheme = !state.darkTheme;
    }
  },
  actions: {
  },
  modules: {
  }
})
